"use client";

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Fragment, useState } from "react";
import { classNames } from "@/helpers/tailwind-class";
import { webAssetsCdnBaseUrl } from "@/shared/constants";

export function VideoTab() {
  const categories = [
    { name: "Music Videos", video: "use-case-videos/music-video" },
    { name: "Sports", video: "use-case-videos/sports" },
    { name: "Marketing", video: "use-case-videos/marketing" },
  ];

  const [tabIndex, setTabIndex] = useState(1);

  return (
    <div className="w-full max-w-xl px-2 sm:px-0">
      <TabGroup selectedIndex={tabIndex} onChange={setTabIndex} as={Fragment}>
        <TabList className="flex space-x-1 rounded-full border border-primary">
          {categories.map((category) => (
            <Tab
              key={category.name}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-full py-2.5 text-sm font-medium leading-5",
                  "!font-bold text-white focus:outline-none",
                  selected ? "bg-primary shadow" : "text-slate-900 hover:bg-primary/80"
                )
              }
            >
              {category.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels className="mt-2">
          {categories.map((category, idx) => (
            <TabPanel
              key={idx}
              className={classNames("animate-fade-in-grow h-full w-full rounded-xl pt-2")}
            >
              <video
                className="aspect-video w-full rounded-lg"
                muted
                autoPlay
                playsInline
                loop
                poster={`${webAssetsCdnBaseUrl}/${category.video}.webp`}
              >
                <source src={`${webAssetsCdnBaseUrl}/${category.video}.mp4`} type="video/mp4" />
              </video>
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </div>
  );
}
