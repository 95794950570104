/**
 * active experiments in posthog. There are two ways to use feature flags
 * - variant key - dynamically render different content based on the value
 * - payload - show value set in posthog. Allow for experiment updates without a deploy
 *
 * Note: the result do not include 'control', and we should never explicitly check for if flag == 'control'.
 * We should always fall back to original behavior for control.
 *
 * Note: `advanced_disable_feature_flags` is only set as `true` when there are active experiments. It checks for the length of keys in activeExperiments
 * That value is set to false to reduce feature flag usage in posthog
 *
 * @example
 * "experiment-key": {
 *   variants: ["variant1", "variant2"],
 *   payload: "string returned";
 * };
 */
export const activeExperiments = {} as const;

/**
 * a type representation of activeExperiments. This is used as the types in the getFlag functions
 */
export type Experiments = {
  [K in keyof typeof activeExperiments]: {
    variants: Exclude<(typeof activeExperiments)[K]["variants"][number], "control">;
    payload: (typeof activeExperiments)[K]["payload"];
  };
};
