"use client";

import { useQuery } from "@tanstack/react-query";
import { getMarketingStats } from "@/shared/marketing-stats";

export function CreatorCount() {
  const { isSuccess, data } = useQuery({
    queryKey: ["marketing-stats"],
    queryFn: async () => {
      return getMarketingStats();
    },
  });

  // the extra spaces allow the animation to run properly. It gives enough space for larger numbers to be animated in
  const text = isSuccess ? `${data.usersCreatedVideo}+` : "             +";

  return (
    <div className="text-left text-sm">
      Trusted by{" "}
      <span>
        {(text || "").split("").map((letter, index) => (
          <span
            key={index}
            className={`transition-opacity duration-500 ${isSuccess ? "opacity-100" : "opacity-0"}`}
            style={{ transitionDelay: `${index / Math.max(text.length, 10)}s` }}
          >
            {letter}
          </span>
        ))}{" "}
        creators
      </span>
    </div>
  );
}
