"use client";

import { ReactNode, useEffect, useRef, useState } from "react";
import { classNames } from "@/helpers/tailwind-class";

interface InfiniteMovingCardsProps {
  items: ReactNode[];
  direction?: "left" | "right";
  speed?: "fast" | "normal" | "slow";
  pauseOnHover?: boolean;
}

export function InfiniteMovingCards({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = true,
}: InfiniteMovingCardsProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    addAnimation();
  }, []);
  const [start, setStart] = useState(false);
  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        if (scrollerRef.current) {
          scrollerRef.current.appendChild(duplicatedItem);
        }
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }
  const getDirection = () => {
    if (!containerRef.current) return;
    const animationDirection = direction === "left" ? "forwards" : "reverse";
    containerRef.current.style.setProperty("--animation-direction", animationDirection);
  };
  const getSpeed = () => {
    if (!containerRef.current) return;

    let duration = "80s";

    if (speed === "fast") {
      duration = "20s";
    } else if (speed === "normal") {
      duration = "40s";
    }

    containerRef.current.style.setProperty("--animation-duration", duration);
  };
  return (
    <div
      ref={containerRef}
      className={
        "scroller relative z-20 w-full max-w-6xl overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)]"
      }
    >
      <ul
        ref={scrollerRef}
        className={classNames(
          "flex w-max min-w-full shrink-0 flex-nowrap gap-4 py-4",
          start ? "animate-scroll" : "",
          pauseOnHover ? "hover:[animation-play-state:paused]" : ""
        )}
      >
        {items.map((item, idx) => (
          <li
            className="relative max-w-full flex-shrink-0 rounded-2xl border border-b-0 border-slate-700 bg-gradient-to-b from-slate-800 to-slate-900 p-4"
            key={idx}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
