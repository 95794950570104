import { PropsWithChildren } from "react";
import { classNames } from "@/helpers/tailwind-class";

export function Title({ children, isH1 = false }: PropsWithChildren<{ isH1?: boolean }>) {
  const Tag = isH1 ? "h1" : "h2";

  return (
    <Tag className="mx-2 max-w-3xl text-center text-3xl font-bold leading-tight tracking-wide sm:text-4xl md:text-5xl lg:text-6xl">
      {children}
    </Tag>
  );
}

export function Description({ children }: PropsWithChildren<{}>) {
  return (
    <p className="mx-4 max-w-4xl text-center text-base tracking-wide text-slate-200 sm:text-lg">
      {children}
    </p>
  );
}

export const tealIndigoPurple = "bg-gradient-to-r from-teal-500 via-indigo-600 to-purple-600";
export const indigoPurple = "bg-gradient-to-r from-indigo-600 to-purple-600";
export const tealIndigo = "bg-gradient-to-r from-teal-500 to-indigo-600";

export function GradientText({ children, gradient }: PropsWithChildren<{ gradient: string }>) {
  return (
    <span className={classNames("bg-clip-text font-extrabold text-transparent", gradient)}>
      {children}
    </span>
  );
}
