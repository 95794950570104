"use client";

import { useEffect } from "react";

export function Testimonial() {
  let lazy = true;

  // Inject Senja script into the head of the dom
  useEffect(() => {
    // Check if the script is already in the head of the dom
    let script: HTMLScriptElement | null = document.querySelector(
      `script[src="https://static.senja.io/dist/platform.js"]`
    );

    if (script) return;

    script = document.createElement("script");
    script.src = "https://static.senja.io/dist/platform.js";
    script.async = true;
    script.defer = true;
    script.type = "text/javascript";
    document.body.append(script);
  }, []);

  return (
    <div
      className="senja-embed w-[90vw] md:w-[80vw] lg:w-[80vw]"
      data-lazyload={lazy}
      data-id={"e34035eb-aacc-4cec-be28-57de782a0369"}
    ></div>
  );
}
