"use client";

import { useSession } from "next-auth/react";
import { usePathname, useSearchParams } from "next/navigation";
import posthog from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { PropsWithChildren, Suspense, useEffect } from "react";
import { activeExperiments } from "./experiments";

if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    autocapture: true,
    capture_pageleave: false,
    capture_pageview: false,
    capture_performance: false,
    // if set to true, it's a significant increase in feature flag usage
    // if set to false, then A/B test stop working since events don't have feature flags.
    // https://posthog.com/docs/feature-flags/common-questions#i-have-very-few-feature_flag_called-events-when-i-look-at-a-flag-but-my-bill-is-still-very-high-why-is-this
    advanced_disable_feature_flags: Object.keys(activeExperiments).length === 0,
  });
}
export function CSPostHogProvider({ children }: PropsWithChildren) {
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.user.id) {
      posthog.identify(session.user.id);
    }
  }, [session?.user.id]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

// useSearchParams() should be wrapped in a suspense boundary
export function PostHogPageView() {
  return (
    <Suspense>
      <PostHogPageTrack />
    </Suspense>
  );
}

function PostHogPageTrack(): null {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  useEffect(() => {
    // Track pageviews
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams && searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      posthog.capture("$pageview", { $current_url: url });
    }
  }, []);

  return null;
}
